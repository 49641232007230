import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Body2Bold, Body2Light, Title2Bold } from "@metyis-ds/typography";
import Layout from "@components/layouts/Layout";
import { MButton } from "@metyis-ds/button";
import { Icon } from "@metyis-ds/icon";
import IllusBarn from "@illustrations/common/barn.svg";
import IllusCity from "@illustrations/common/city.svg";
import IllusCloud1 from "@illustrations/common/cloud1.svg";
import IllusCloud2 from "@illustrations/common/cloud2.svg";
import "./UserPromptModal.style.css";

interface IUserPromptModalProps {
  show: boolean;
  content: {
    title: string;
    game: string;
    description: string;
    no: string;
    yes: string;
  };
  onClose: () => void;
  onNo: () => void;
  onYes: () => void;
}

const UserPromptModal: React.FC<IUserPromptModalProps> = ({
  show,
  content,
  onClose,
  onNo,
  onYes
}) => {
  const [el, setEl] = useState<Element | undefined>(undefined);

  useEffect(() => {
    if (!el) {
      const el = document.createElement("div");
      setEl(el);

      const modalEl = document.getElementById("modal-root");

      modalEl?.appendChild(el);

      return () => {
        modalEl?.removeChild(el);
      };
    }
  }, []);

  const children = (
    <Layout
      division="full"
      className="absolute inset-0 h-screen w-screen z-modal"
    >
      <div className="flex items-center justify-center">
        <div className="absolute h-full w-full bg-grey-medium opacity-70 inset-0 z-" />
        <div className=" w-auto h-1/3 bg-white-light z-10 grid grid-cols-base rounded-2xl">
          <div className="relative rounded-l-2xl overflow-hidden m-px">
            <IllusCloud1 className="absolute top-9 right-8" />
            <IllusCloud2 className="absolute top-20 left-8" />
            <IllusCity className="absolute bottom-0 right-0" />
            <IllusBarn className="absolute bottom-0 left-0" />
          </div>
          <div className="custom-content bg-white-light">
            <Icon name="Close" className="justify-self-end" onClick={onClose} />
            <Body2Bold>{content.game}</Body2Bold>
            <Title2Bold className="self-end">{content.title}</Title2Bold>
            <Body2Light>{content.description}</Body2Light>
            <div className="flex flex-row gap-md">
              <MButton onClick={onNo}>{content.no}</MButton>
              <MButton onClick={onYes} filled>
                {content.yes}
              </MButton>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );

  return show && el ? createPortal(children, el) : <></>;
};

export default UserPromptModal;
