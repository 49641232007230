import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import api from "@api";
import { authState } from "@state/auth";
import { ESteps, stepState } from "@state/step";
import UserPromptModal from "@templates/user-prompt-modal/UserPromptModal";
import content from "./WelcomeBackModal.content";
import { useSetRecoilState } from "recoil";

interface IWelcomeBackModalProps {}

const NO_SHOW_WELCOME_PERIOD_SECONDS = 50;
const WelcomeBackModal: React.FC<IWelcomeBackModalProps> = () => {
  const [show, setShow] = useState(false);
  const [currentStep, setCurrentStep] = useState<ESteps | undefined>();

  const [authValue, setAuthValue] = useRecoilState(authState);
  const setStep = useSetRecoilState(stepState);

  useEffect(() => {
    if (authValue.gameid && authValue.userid) {
      
      api
        .postCheckGame(authValue)
        .then(({ data }) => {
          if(data.joined) {
            if(new Date().getTime() - (data.joined * 1000) < (NO_SHOW_WELCOME_PERIOD_SECONDS * 1000)) {
              setShow(false)
              setStep(data.step as ESteps)
            } else {
              setShow(true)
            }
          } else {
            setShow(true);
          }
          setCurrentStep(data.step as ESteps);
        })
        .catch(() => {
          setAuthValue({
            gameid: "",
            userid: "",
            random: -1
          });
          setStep(ESteps.LOGIN);
        });
    }
  }, []);

  const onNo = () => {
    setShow(false);
    setAuthValue({
      gameid: "",
      userid: ""
    });
    setStep(ESteps.LOGIN);
  };

  const onYes = () => {
    setShow(false);
    if (typeof currentStep === "number") {
      setStep(currentStep);
    }
  };

  return (
    <UserPromptModal
      content={content}
      show={show}
      onClose={() => setShow(false)}
      onNo={onNo}
      onYes={onYes}
    />
  );
};

export default WelcomeBackModal;
